import React from 'react';
import {
  required,
  email,
  confirmation,
  format,
  inclusion,
  numericality
} from 'redux-form-validators';
import moment from "moment";

const requiredValidator = required({
  msg: 'This field is required',
});

const passwordValidator = format({
  with: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/i,
  msg:
    'Password must be 8 characters or longer and must contain at least one number and one character',
});

const tooYoung = value => {
  return moment().isBefore(moment(value).add(13, 'years')) ? 'You must be at least 13 years old. Please let your parents know that you want to use this application' : undefined;
}

const numberValidator = numericality({
  int: true,
  '>=': 10,
  msg: {'>=': 'The hourly rate must be minimum 10 USD / hour'},
});

export const validations = {
  loginForm: {
    email: [
      requiredValidator,
      email({
        msg: 'Invalid email address',
      }),
    ],
    password: [requiredValidator],
  },
  registerForm: {
    first_name: [requiredValidator],
    last_name: [requiredValidator],
    role: [requiredValidator],
    dob: [requiredValidator, tooYoung],
    agreement: [requiredValidator, inclusion({ in: ['true'],  msg: 'This field is required' })],
    email: [
      requiredValidator,
      email({
        msg: 'Invalid email address',
      }),
    ],
    password: [requiredValidator, passwordValidator],
    password_confirmation: [
      requiredValidator,
      passwordValidator,
      confirmation({
        field: 'password',
        msg: 'This field is not match with password field',
      }),
    ],
  },
  forgotPassword: {
    email: [
      requiredValidator,
      email({
        msg: 'Invalid email address',
      }),
    ],
  },
  resetPassword: {
    email: [
      requiredValidator,
      email({
        msg: 'Invalid email address',
      }),
    ],
    password: [requiredValidator, passwordValidator],
    password_confirmation: [
      requiredValidator,
      passwordValidator,
      confirmation({
        field: 'password',
        msg: 'This field is not match with password field',
      }),
    ],
  },
  createAccountPassword: {
    password: [requiredValidator, passwordValidator],
    password_confirmation: [
      requiredValidator,
      passwordValidator,
      confirmation({
        field: 'password',
        msg: 'This field is not match with password field',
      }),
    ],
  },
  resetAccountPasswordForm: {
    current_password: [
      requiredValidator,
      // format({
      //     with: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/i,
      //     msg: "Password must be 8 characters or longer, must contain at least a number and one of the following special chars !@#$%^&*"
      // })
    ],
    password: [requiredValidator, passwordValidator],
    password_confirmation: [
      requiredValidator,
      passwordValidator,
      confirmation({
        field: 'password',
        msg: 'This field is not match with password field',
      }),
    ],
  },
  studentProfileEditForm: {
    // subjects: [
    //     required({
    //         msg: "This field is required",
    //     }),
    // ],
    about: [requiredValidator],
    sex: [requiredValidator],
    dob: [requiredValidator, tooYoung],
  },
  tutorProfileEditForm: {
    years_of_experience: [requiredValidator],
    hourly_rate: [requiredValidator, numberValidator],
    // subjects: [
    //     required({
    //         msg: "This field is required",
    //     }),
    // ],
    education_degree: [requiredValidator],
    // about: [requiredValidator],
    sex: [requiredValidator],
    dob: [requiredValidator, tooYoung],
    phone: [
      format({
        with: /^[0-9+]*$/,
        msg: 'Invalid phone number',
      }),
    ],
  },
  addClassForm: {
    date: [requiredValidator],
    start_time: [requiredValidator],
    end_time: [requiredValidator],
    subjects: [requiredValidator],
    students: [requiredValidator],
    recurrentToDate: [requiredValidator],
  },
  editClassForm: {
    date: [requiredValidator],
    start_time: [requiredValidator],
    end_time: [requiredValidator],
    subjects: [requiredValidator],
    students: [requiredValidator],
  },
  addAvailabilityForm: {
    date: [requiredValidator],
    start_time: [requiredValidator],
    end_time: [requiredValidator],
    recurrentToDate: [requiredValidator],
  },
  editAvailabilityForm: {
    date: [requiredValidator],
    start_time: [requiredValidator],
    end_time: [requiredValidator],
  },
  userDetailsForm: {
    role: [requiredValidator],
    dob: [requiredValidator, tooYoung],
  },
  saveReviewForm: {
    rating: [requiredValidator],
    message: [requiredValidator],
  },
  feeForm: {
    name: [requiredValidator],
    contract_agreement: [requiredValidator, inclusion({ in: ['true'],  msg: 'This field is required' })],
    service_fee_agreement: [requiredValidator, inclusion({ in: ['true'],  msg: 'This field is required' })],
  },
  contactForm: {
    name: [requiredValidator],
    email: [
      requiredValidator,
      email({
        msg: 'Invalid email address',
      }),
    ],
    message: [requiredValidator],
  }
};
