import { validations } from './siteValidations';
import { API_HOST, API_PROTOCOL } from '../api/api';
import { buildOauthPath } from '../api/base';

export const validate = formName => values => {
  const errors = {};
  for (const field in validations[formName]) {
    let value = values[field];
    if (value) value = value.toString().trim();
    errors[field] = validations[formName][field]
      .map(validateField => {
        return validateField(value, values);
      })
      .find(x => x);
  }
  return errors;
};

export const mapStudents = studentsList => {
  const arrStudents = [];
  studentsList.map(student =>
    arrStudents.push({ id: student.uuid, value: student.uuid_user, name: student.full_name })
  );
  return arrStudents;
};

export const mapSubjects = subjectsList => {
  const arrSubjects = [];
  if (subjectsList.length > 0) {
    subjectsList.map(subject => {
      if (subject.children !== undefined) {
        if (subject.children.length > 0) {
          arrSubjects.push({
            value: subject.uuid,
            name: subject.name,
            isParent: true,
          });
          subject.children.map(children => {
            arrSubjects.push({
              value: children.uuid,
              name: children.name,
              isParent: false,
            });
          });
        }
      }
    });
  }
  return arrSubjects;
};

export const mapTutorSubjects = subjectsList => {
  const arrSubjects = [];
  if (subjectsList.length > 0) {
    subjectsList.map(subject => {
      arrSubjects.push({
        value: subject.uuid,
        name: subject.name,
        isParent: false,
      });
    });
  }
  return arrSubjects;
};

export const mapUserSubjects = subjectsList => {
  const arrSubjects = [];
  subjectsList.map((subject, i) => {
    arrSubjects.push(' ' + subject.name);
  });
  return arrSubjects;
};

export const mapUserSubjectsUuid = subjectsList => {
  const arrSubjects = [];
  subjectsList.map((subject, i) => {
    arrSubjects.push(subject.uuid);
  });
  return arrSubjects;
};

export const mapUserStudents = studentsList => {
  const arrStudents = [];
  studentsList.map(student => arrStudents.push(student.full_name));
  return arrStudents;
};

export const mapUserStudentsUuid = studentsList => {
  const arrStudents = [];
  studentsList.map(student => arrStudents.push(student.uuid));
  return arrStudents;
};

export const mapUserStudentsUuidUser = studentsList => {
  const arrStudents = [];
  studentsList.map(student => arrStudents.push(student.uuid_user));
  return arrStudents;
};

export function getApiImages(url) {
  return buildOauthPath(url);
}

export const isCurrentRoute = (router, route) => {
  if (Array.isArray(route)) {
    let isCurrentRoute = false;
    route.map(url => {
      if (router.pathname === url) {
        isCurrentRoute = true;
      }
    });
    return isCurrentRoute;
  } else {
    return router.pathname === route;
  }
};

export const getAvatarLetters = fullName => {
  if (fullName) {
    const arrNames = fullName.split(' ');
    let name;
    if (arrNames[1]) {
      name = arrNames[0].charAt(0) + arrNames[1].charAt(0);
    } else {
      name = arrNames[0].charAt(0);
    }
    return name.toUpperCase();
  }
  return '';
};

export const getShortName = fullName => {
  if (fullName) {
    const arrNames = fullName.split(' ');
    return arrNames[0] + ' ' + arrNames[1].charAt(0) + '.';
  }
  return '';
};
